const menuItems = document.querySelectorAll('.MegaMenu--Item');
const regex = /Escape|Enter/;

let timeOutID = null;

menuItems.forEach(el => {
	// Skip dropdown functionality if menu item doesn't have a submenu
	if (el.className.includes('MegaMenu--Item--Solo')) {
		return;
	}

	el.addEventListener('mouseover', (e: MouseEvent) => {
		const target = e.target;
		clearTimeout(timeOutID)
		// https://stackoverflow.com/questions/28900077/why-is-event-target-not-element-in-typescript
		// Not all event targets are elements, so checking here to ensure type-safety.
		if (!(target instanceof HTMLElement)) return;

		const parent = target.closest('.MegaMenu--Item');
		toggleMenu(true, parent);
	});

	el.addEventListener('mouseleave', (e: MouseEvent) => {
		const target = e.target;
		if (!(target instanceof HTMLElement)) return;

		const parent = target.closest('.MegaMenu--Item');
		timeOutID = setTimeout(()=> toggleMenu(false, parent),300)
	});
	
	el.addEventListener('click', (e: MouseEvent) => {
		const target = e.target;
		if (!(target instanceof HTMLElement)) return;

		const parent = target.closest('.MegaMenu--Item');
		const isActive = parent.className.includes('open');
		const isSubNav = target.className.includes('MegaMenu--Sub')
		if(!isSubNav) toggleMenu(!isActive, parent);
	});

	el.addEventListener('keydown', (e: KeyboardEvent) => {
		const target = e.target;
		if (!(target instanceof HTMLElement)) return;

		// Ignore all other keys
		if (!regex.test(e.key)) {
			return;
		}

		// Don't override native behavior if typing
		if (regex.test(e.key) && /input|textarea/i.test(target.tagName)) {
			return;
		}

		const parent = target.closest('.MegaMenu--Item');
		const isActive = parent.className.includes('open');

		switch (e.key) {
			case 'Escape':
				if (isActive) {
					e.preventDefault();
					toggleMenu(false, parent);
				}
				break;
			case 'Enter':
				if (!isActive) {
					e.preventDefault();
					toggleMenu(true, parent);
				}
		}
	});
});

function toggleMenu(open: boolean, parent: Element) {
	const link = parent.querySelector('.MegaMenu--Link');
	if (!link) return;

	if (open) {
		menuItems.forEach(el => toggleMenu(false, el));
		parent.className = `${parent.className} open`;
		link.setAttribute('aria-expanded', 'true');
	} else {
		parent.className = parent.className.replace(' open', '');
		link.setAttribute('aria-expanded', 'false');
	}
}
